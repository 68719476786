import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContatoSucessoPage = () => (
  <Layout>
    <SEO title="Contato Sucesso" />
    <section className="intro">
      <h2>Sucesso</h2>
      <p>Muito obrigado pela mensagem! Estaremos em contato em breve. <Link to="/">Voltar à página inicial</Link></p>
    </section>
  </Layout>
)

export default ContatoSucessoPage
